<template>
  <TeamfightToggle
      v-model="useProdApiCookie().value"
      label="Prod API"
      @toggle="toggleClient"
  />
</template>

<script lang="ts" setup>
import { useProdApiCookie } from "~/utils/cookie";

const toggleClient = (i: boolean) => {
  useProdApiCookie().value = i.toString();
};
</script>