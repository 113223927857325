<template>
  <!-- hamburger button -->
  <TeamfightButton
      class="mobile-nav-toggle-button"
      variant="tertiary"
      @click="store.toggleMobileNavigation"
  >
    <div class="mobile-nav-toggle-button__content">
      <span
          :class="{
          '--open': store.isMobileNavigationOpen,
        }"
          aria-hidden="true"
          class="mobile-nav-toggle-button__line mobile-nav-toggle-button__line--first"
      />
      <span
          :class="{
          '--open': store.isMobileNavigationOpen,
        }"
          aria-hidden="true"
          class="mobile-nav-toggle-button__line mobile-nav-toggle-button__line--second"
      />
      <span
          :class="{
          '--open': store.isMobileNavigationOpen,
        }"
          aria-hidden="true"
          class="mobile-nav-toggle-button__line mobile-nav-toggle-button__line--third"
      />
    </div>
  </TeamfightButton>

  <!-- mobile nav -->
  <Teleport to="body">
    <Transition name="mobile-nav">
      <div v-if="store.isMobileNavigationOpen" class="mobile-nav">
        <div class="mobile-nav__content">
          <div class="mobile-nav__body">
            <AdminOnly alwaysShow subtle>
              <div class="mobile-nav__actions">
                <LocalOnly>
                  <ProductionAPIToggle/>
                </LocalOnly>

                <AdminModeToggle/>
              </div>
            </AdminOnly>

            <MobileNavItem
                v-for="{ subItems, ...item } in navList"
                :key="item.name"
                v-bind="item"
            >
              <MobileNavSubItem
                  v-for="subItem in subItems"
                  :key="subItem.name"
                  v-bind="subItem"
              />
            </MobileNavItem>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
import { useGeneralStore } from '~/stores/GeneralStore';
import { useUserStore } from '~/stores/UserStore';
import { navList } from '~/utils/nav';
import ProductionAPIToggle from "~/components/ProductionAPIToggle.vue";
import AdminModeToggle from "~/components/AdminModeToggle.vue";

const store = useGeneralStore();
const userStore = useUserStore();
const { md } = useScreen();
const route = useRoute();

watch(md, (isMdOrLarger) => {
  if (isMdOrLarger && store.isMobileNavigationOpen) {
    store.closeMobileNavigation();
  }
});

watch(
    () => route.path,
    () => {
      store.closeMobileNavigation();
    }
);
</script>

<style lang="scss" scoped>
.mobile-nav-toggle-button {
  position: relative;
  color: var(--UI-Text);
  background-color: transparent;
  border: none;
  width: 3rem;
  height: 2rem;

  &:focus {
    outline: none;
  }

  @include md {
    display: none;
  }

  &__content {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 2.5rem;
    transform: translate(-50%);
  }

  &__line {
    display: block;
    position: absolute;
    height: 0.25rem;
    width: 2.5rem;
    background-color: currentColor;
    transition: all 0.5s ease-in-out;

    &--first {
      transform: translateY(-0.75rem);

      &.--open {
        transform: rotate(45deg);
      }
    }

    &--second {
      &.--open {
        opacity: 0;
      }
    }

    &--third {
      transform: translateY(0.75rem);

      &.--open {
        transform: rotate(-45deg);
      }
    }
  }
}

.mobile-nav {
  position: fixed;
  top: var(--TopNav-Height);
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: var(--UI-Primary);

  overflow-y: auto;

  .mobile-nav__actions {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__body {
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    row-gap: 1rem;
    overflow-y: auto;
  }
}

// mobile-nav transition
.mobile-nav-enter-active {
  animation: slide-in 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
}

.mobile-nav-leave-active {
  animation: slide-out 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
}

@keyframes slide-in {
  0% {
    transform: translate(-100%, 0);
    opacity: 0;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    transform: translate(0%, 0);
    opacity: 1;
  }
}

@keyframes slide-out {
  0% {
    transform: translate(0%, 0);
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  80% {
    opacity: 0.1;
  }

  100% {
    transform: translate(-100%, 0);
    opacity: 0;
  }
}
</style>
