<template>
  <TeamfightToggle
      v-model="userStore.adminMode"
      :labelLink="`${getAdminUrl()}/home`"
      label="Admin Mode"
      @toggle="toggleAdminMode"
  />
</template>

<script lang="ts" setup>
import { getAdminUrl } from "~/helpers/getAdminUrl";
import { useUserStore } from "~/stores/UserStore";

const userStore = useUserStore();

const toggleAdminMode = (i: boolean) => {
  userStore.adminMode = i;
};
</script>